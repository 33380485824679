<template>
  <content-with-sidebar class="news-wrapper h-100">
    <!-- news -->
    <b-row class="news-list-wrapper">
      <b-overlay
        :show="$store.state.app.loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
      <b-col
        v-for="(item, key) in newsData"
        :key="key"
        md="4"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link
            :to="{ name: 'news-detail', params: { id: item.id_new } }"
            class="image-container"
          >
            <b-carousel
              v-if="item.n_imgPreview"
              id="carousel-fade"
              fade
              indicators
              :interval="3000"
            >
              <b-carousel-slide
                v-for="(image, key) in JSON.parse(item.n_imgPreview)"
                :key="key"
                :img-src="server + image"
              />
            </b-carousel>
            <!-- <div class="top">
              <div class="text p-2">
                <small v-html="item.status" />
              </div>
            </div> -->
            <!-- <b-button
              :variant="`${item.iStatus !== '0' && 'success'}`"
              class="top-right btn-icon rounded-circle p-0"
              size="sm"
            >
              <feather-icon
                icon="InfoIcon"
                size="24"
                class="cursor-pointer"
              />
            </b-button> -->
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'news-detail', params: { id: item.id_new } }"
                class="news-title-truncate text-body-heading"
              >
                {{ item.n_header }}
              </b-link>
            </b-card-title>
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  href="javascript:void(0)"
                  size="24"
                  :text="avatarText(item.UsName)"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">от</small>
                <small>
                  <b-link
                    class="text-body"
                    :to="{ name: 'apps-users-view', params: { id: 1 } }"
                  >{{ item.UsName }}</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ item.n_date }}</small>
                <b-link
                  v-if="item.n_link"
                  target="blank"
                  class="ml-50"
                  :href="item.n_link"
                >
                  <feather-icon icon="LinkIcon" />
                </b-link>
              </b-media-body>
            </b-media>
            <!-- <div>
              <small
                v-if="item.n_datePost"
                class="text-body"
              >
                <span>Активность с {{ item.n_datePost }}</span>
                <span v-if="item.n_datePostEnd"> по {{ item.n_datePostEnd }}</span>
              </small>
            </div> -->
            <div class="my-1 py-25">
              <b-link>
                <b-badge
                  pill
                  class="mr-75"
                  :variant="tagsColor(item.n_typeView)"
                >
                  {{ item.n_typeView }}
                </b-badge>
              </b-link>
            </div>
            <b-card-text class="news-content-truncate">
              <div v-html="item.n_teaser" />
            </b-card-text>
            <div
              v-if="(item.n_fileLink && item.n_fileName)"
            >
              <b-link
                v-for="(link, key) in item.n_fileLink"
                :key="key"
                :href="server + link"
                target="blank"
                class="d-block"
              >
                {{ item.n_fileName[key] }}
              </b-link>
            </div>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <b-link
                :to="{ name: 'news-detail', params: { id: item.id_new } }"
                target="blank"
                class="font-weight-bold"
              >
                {{ $t('Details...') }}
              </b-link>
              <!-- <b-dropdown
                variant="link"
                no-caret
                size="sm"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body"
                  />
                </template>
                <b-dropdown-item :to="{ name: 'news-detail', params: { id: item.id_new } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="item.iStatus < 1"
                  @click="updateNewsStatus({
                    id: item.id_new,
                    status: 1
                  })"
                >
                  <feather-icon icon="CheckSquareIcon" />
                  <span class="align-middle ml-50">{{ $t('Publish') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="item.iStatus > 0"
                  @click="updateNewsStatus({
                    id: item.id_new,
                    status: 0
                  })"
                >
                  <feather-icon icon="XSquareIcon" />
                  <span class="align-middle ml-50">{{ $t('Remove from publication') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteNews(item.id_new)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown> -->
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        v-if="newsData && newsData.length"
        cols="12"
      >
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <!--/ news -->

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="news-sidebar py-2 py-lg-0"
    >
      <!-- <div
        class="d-flex justify-content-end mb-2"
      >
        <b-button
          :to="{ name: 'apps-news-add' }"
          variant="primary"
        >
          {{ $t('Add') }}
        </b-button>
      </div> -->
      <!-- <b-form-group
        label="Статус"
        label-for="news-status"
        class="mb-2"
      >
        <v-select
          id="news-status"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="val => val.value"
          :options="newsStatuses"
          :value="xparam1"
          :filterable="false"
          :clearable="false"
          @input="(val) => {
            xparam1 = val
          }"
        />
      </b-form-group> -->
      <!-- categories -->
      <div class="news-categories">
        <h6 class="section-label mb-1">
          Тип материала
        </h6>

        <div
          v-for="(category, key) in newsSidebar.categories"
          :key="key"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="category.color"
              class="mr-75"
            >
              <feather-icon
                :icon="category.icon"
                size="16"
              />
            </b-avatar>
          </b-link>
          <b-link
            @click="setCatId(category.value)"
          >
            <div class="news-category-title text-body">
              {{ category.label }}
            </div>
          </b-link>
        </div>
      </div>
      <!--/ categories -->
      <!-- tags -->
      <!-- <div class="news-tags mt-3">
        <h6 class="section-label mb-1">
          Тип отображения
        </h6>
        <b-link
          v-for="(tag, key) in newsSidebar.tags"
          :key="key"
          class="d-inline-block mb-75"
          @click="setTagId(tag.value)"
        >
          <b-badge
            pill
            class="mr-75"
            :variant="tagsColor(tag.value)"
          >
            {{ tag.label }}
          </b-badge>
        </b-link>
      </div> -->
      <!--/ tags -->
    </div>
    <!--/ sidebar -->
  </content-with-sidebar>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardTitle,
  BCardBody, BLink, BPagination,
  BMedia, BMediaAside, BMediaBody, BBadge, BAvatar,
  BCarousel, BCarouselSlide,
  // BDropdown,
  // BDropdownItem,
  // BButton,
  BOverlay,
  // BFormGroup,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { kFormatter, avatarText } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import store from '@/store'
import newsAndDocumentsStoreModule from '../../newsAndDocumentsStoreModule'
import useNewsList from './useNewsList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BLink,
    BPagination,
    ContentWithSidebar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    // BDropdown,
    // BDropdownItem,
    // BButton,
    BOverlay,
    // vSelect,
    // BFormGroup,
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Standart') return 'light-primary'
      if (tag === 'Slider') return 'light-success'
      if (tag === 'video') return 'light-warning'
      return 'light-primary'
    },
  },
  setup(_, ctx) {
    const APP_STORE_MODULE_NAME = 'app-news'
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, newsAndDocumentsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const server = process.env.VUE_APP_SERVER
    const api = process.env.VUE_APP_API

    const {
      newsData,
      perPage,
      currentPage,
      total,
      newsSidebar,
      fetchNews,
      deleteNews,
      updateNewsStatus,

      newsStatuses,
      xparam1,
      xparam2,
      xparam3,
    } = useNewsList()

    if (ctx.root.$route.query.cat_id) {
      xparam2.value = ctx.root.$route.query.cat_id
    }

    if (ctx.root.$route.query.tag_id) {
      xparam3.value = ctx.root.$route.query.tag_id
    }

    const setCatId = catId => {
      ctx.root.$router.replace({ query: { ...ctx.root.$route.query, cat_id: catId } }).then(() => {
        xparam2.value = catId
      })
    }

    const setTagId = tagId => {
      ctx.root.$router.replace({ query: { ...ctx.root.$route.query, tag_id: tagId } }).then(() => {
        xparam3.value = tagId
      })
    }

    if (!ctx.root.$route.query.cat_id && !ctx.root.$route.query.tag_id) {
      fetchNews()
    }

    return {
      newsData,
      perPage,
      currentPage,
      total,
      newsSidebar,

      avatarText,

      deleteNews,
      updateNewsStatus,

      newsStatuses,
      xparam1,

      setCatId,
      setTagId,

      server,
      api,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
  .top-right {
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .news-list-wrapper {
    position: relative;
  }
  .image {
    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  .top {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: left;
    border-top-left-radius: calc(0.428rem - 1px);
    border-top-right-radius: calc(0.428rem - 1px);
    overflow: auto;
  }

  .image-container {
    position: relative;
  }

  .image-container:hover .image {
    opacity: 0.3;
  }

  .image-container:hover .top {
    opacity: 1;
  }

  .text {
    background-color: #7367f0;
    color: white;
  }
</style>
